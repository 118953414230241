import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import portfolioImage1 from '../assets/portfolio-1.jpg';
import portfolioImage2 from '../assets/portfolio-2.jpg';
import portfolioImage3 from '../assets/portfolio-3.jpg';
import portfolioImage4 from '../assets/portfolio-8.jpg';
import portfolioImage5 from '../assets/portfolio-4.jpg';
import portfolioImage6 from '../assets/portfolio-9.jpg';

const Portfolio = ({ theme }) => (
  <MDBContainer fluid className={theme === 'dark' ? "portfolio-wrapper-dark" : "portfolio-wrapper-light"} id="portfolio">
    <MDBContainer className="py-3">

      <MDBRow className="pt-4">
        <MDBCol xl="12" lg="12">
          <MDBContainer fluid className="section-title text-center mb-4">
            <MDBAnimation reveal type="fadeInDown">
              <h2 className={theme === 'dark' ? "title-dark text-white mb-2" : "title-light mb-2"}>My Latest Projects</h2>
            </MDBAnimation>
            <p className={theme === 'dark' ? "quote-dark" : "quote-light"}>“Difficulties mastered are opportunities won.” -- W. Churchill</p>
          </MDBContainer>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xl="4" lg="4" md="6" sm="12">
          <div className="project project-style--1 shade">
            <div className="thumbnail">
              <a href="#" className="thumbnail-a disabled">
                <img className="w-100" src={portfolioImage1} alt="project" />
              </a>
            </div>

            <div className="project-content">
              <p className="projecttype">Mobile Development</p>
              <h4 className="thumb-title">
                <a href="#" className="disabled">React Native movie browsing app</a>
              </h4>
              <div className="project-btn">
                <a href="https://github.com/ozzychel/iMovieRate#readme" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Read More</a>
                <a href="https://www.youtube.com/watch?v=bd-IM4Bdd7I" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Watch Demo</a>
              </div>
            </div>
          </div>
        </MDBCol>

        <MDBCol xl="4" lg="4" md="6" sm="12">
          <div className="project project-style--1 shade">
            <div className="thumbnail">
              <a href="#" className="thumbnail-a disabled">
                <img className="w-100" src={portfolioImage2} alt="project" />
              </a>
            </div>

            <div className="project-content">
              <p className="projecttype">Web Development</p>
              <h4 className="thumb-title">
                <a href="#" className="disabled">Availability calendar Microservice</a>
              </h4>
              <div className="project-btn">
                <a href="https://github.com/Hotellooo/calendar#readme" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Read More</a>
                <a href="https://www.youtube.com/watch?v=A4WlFbRBHOU" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Watch Demo</a>
              </div>
            </div>
          </div>
        </MDBCol>

        <MDBCol xl="4" lg="4" md="6" sm="12">
          <div className="project project-style--1 shade">
            <div className="thumbnail">
              <a href="#" className="thumbnail-a disabled">
                <img className="w-100" src={portfolioImage5} alt="project" />
              </a>
            </div>

            <div className="project-content">
              <p className="projecttype">Development & Test Automation</p>
              <h4 className="thumb-title">
                <a href="#" className="disabled">iOS/Android Mobile Test Automation</a>
              </h4>
              <div className="project-btn">
                <a href="https://github.com/ozzychel/wflwr_appium_test_suite#readme" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Read More</a>
                <a href="https://youtu.be/62tcdHnxRwE" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Watch Demo</a>
              </div>
            </div>
          </div>
        </MDBCol>

        <MDBCol xl="4" lg="4" md="6" sm="12">
          <div className="project project-style--1 shade">
            <div className="thumbnail">
              <a href="#" className="thumbnail-a disabled">
                <img className="w-100" src={portfolioImage6} alt="project" />
              </a>
            </div>

            <div className="project-content">
              <p className="projecttype">Development & Test Automation</p>
              <h4 className="thumb-title">
                <a href="#" className="disabled">OTT devices E2E Test Automation</a>
              </h4>
              <div className="project-btn">
                <a href="https://youtu.be/OvRHJDMns-8" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Watch Demo</a>
              </div>
            </div>
          </div>
        </MDBCol>

        <MDBCol xl="4" lg="4" md="6" sm="12">
          <div className="project project-style--1 shade">
            <div className="thumbnail">
              <a href="#" className="thumbnail-a disabled">
                <img className="w-100" src={portfolioImage3} alt="project" />
              </a>
            </div>

            <div className="project-content">
              <p className="projecttype">Backend Development</p>
              <h4 className="thumb-title">
                <a href="#" className="disabled">System design, stress testing, scaling</a>
              </h4>
              <div className="project-btn">
                <a href="https://github.com/ozzychel/SDP#readme" className="rn-btn-style-1" target="_blank" rel="noopener noreferrer">Read More</a>
              </div>
            </div>
          </div>
        </MDBCol>

        <MDBCol xl="4" lg="4" md="6" sm="12">
          <div className="project project-style--1 shade">
            <div className="thumbnail">
              <a href="#" className="thumbnail-a disabled">
                <img className="w-100" src={portfolioImage4} alt="project" />
              </a>
            </div>

            <div className="project-content">
              <p className="projecttype">Deno Development</p>
              <h4 className="thumb-title">
                <a href="#" className="disabled">CSV splitting tool for Deno runtime</a>
              </h4>
              <div className="project-btn">
                <a href="https://github.com/ozzychel" className="rn-btn-style-1 disabled" target="_blank" rel="noopener noreferrer">IN DEVELOPMENT</a>
              </div>
            </div>
          </div>
        </MDBCol>

      </MDBRow>


      <MDBRow>
        <MDBCol>
          <div className="view-more-btn my-4 text-center">
            <a href="https://github.com/ozzychel" className={theme === 'dark' ? "rn-btn-style-2-dark" : "rn-btn-style-2-light"} target="_blank" rel="noopener noreferrer"><span>VIEW MORE</span></a>
          </div>
        </MDBCol>
      </MDBRow>

    </MDBContainer>
  </MDBContainer>

);

export default Portfolio;
