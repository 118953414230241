import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBView,
  MDBContainer,
  MDBAnimation,
  MDBIcon
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from 'react-scroll';
import logo from '../assets/logo-symbol.png';

class Navbar extends Component {
  constructor(props) {
    super(props);``
    this.state = {
      collapsed: false,
      count: 0,
      role: 'Software Engineer,',
    };
    this.handleTogglerClick = this.handleTogglerClick.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 3000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const roles = ['Software Engineer,', 'Full Stack Developer,', 'UI/UX Designer,', 'Problem Solver,', 'Contributor,', 'Tech Enthusiast,', 'Creator,', 'Experimenter,'];
    const getCount = () => {
      if (this.state.count === roles.length - 1) this.setState({ count: 0 });
      else this.setState({ count: this.state.count + 1 });
      return this.state.count;
    };
    this.setState({
      count: getCount(),
      role: roles[this.state.count],
    });
  }

  handleTogglerClick() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    return (
      <div id="home">
        <Router>
          <div>
            <MDBNavbar
              id="navbar"
              color={this.props.theme === 'dark' ? 'black' : 'white'}
              dark
              expand="md"
              fixed="top"
              scrolling
              transparent={!this.state.collapsed && this.props.theme === 'dark'}
              className={this.props.theme === 'dark' ? "navbar-wrap" : "navbar-wrap shade"}
              >
              <MDBContainer fluid>
                <MDBNavbarBrand className="order-md-1 order-lg-1 order-xl-1">
                  <img src={logo} className={this.props.theme === 'dark' ? 'nav-logo-dark' : 'nav-logo-light'}/>
                </MDBNavbarBrand>
               <MDBNavbarToggler onClick={this.handleTogglerClick}
                 image={`https://mdbootstrap.com/img/svg/hamburger7.svg?color=${this.props.theme === 'dark' ? 'FFFFFF' : '000000'}`}
                 className="order-2 order-xl-3 order-lg-3 order-md-3 order-sm-2"
                 right/>
                <MDBCollapse isOpen={this.state.collapsed} navbar
                className="order-3 order-xl-2 order-lg-2 order-md-2 order-sm-3">
                  <MDBNavbarNav left id="leftbar">
                    <MDBNavItem>
                      <Link activeClass="current" className={this.props.theme === 'dark' ? 'alink' : 'alink-light'} to="home" smooth={true} spy offset={-50}>Home</Link>
                    </MDBNavItem>
                    <MDBNavItem>
                      <Link activeClass="current" className={this.props.theme === 'dark' ? 'alink' : 'alink-light'} to="about" smooth={true} spy offset={-50}>About</Link>
                    </MDBNavItem>
                    <MDBNavItem>
                      <Link activeClass="current" className={this.props.theme === 'dark' ? 'alink' : 'alink-light'} to="portfolio" smooth={true} spy offset={-50}>Portfolio</Link>
                    </MDBNavItem>
                    <MDBNavItem>
                      <Link activeClass="current" className={this.props.theme === 'dark' ? 'alink' : 'alink-light'} to="contacts" smooth={true} spy offset={-50}>Contacts</Link>
                    </MDBNavItem>
                  </MDBNavbarNav>
                </MDBCollapse>
                  <MDBNavbarNav left className="order-1 order-xl-4 order-lg-4 order-md-4 order-sm-1 rightnav">
                    <MDBNavItem className="order-2 order-xl-1 order-lg-1 order-md-1 order-sm-2">
                      <div className="social-cont">
                        <ul className="rn-lg-size d-flex justify-content-center">
                          <li>
                            <a className={this.props.theme === 'dark' ? "social-cont-li-a" : 'social-cont-li-a color--black'} href="https://www.linkedin.com/in/andrey-petunin/" target="_blank" rel="noopener noreferrer">
                              <MDBIcon fab icon="linkedin" size="lg" /></a>
                          </li>
                          <li>
                            <a className={this.props.theme === 'dark' ? "social-cont-li-a" : 'social-cont-li-a color--black'} href="https://github.com/ozzychel" target="_blank" rel="noopener noreferrer">
                              <MDBIcon fab icon="github" size="lg" /></a>
                          </li>
                            <li>
                              <a className={this.props.theme === 'dark' ? "social-cont-li-a" : 'social-cont-li-a color--black'} href="https://www.instagram.com/ozzy_chel/?hl=en" target="_blank" rel="noopener noreferrer">
                                <MDBIcon fab icon="instagram" size="lg" /></a>
                            </li>
                        </ul>
                      </div>
                    </MDBNavItem>
                    <MDBNavItem className="order-1 order-xl-2 order-lg-2 order-md-2 order-sm-1">
                      <div className="theme-switch-wrapper">
                        <label className="theme-switch" htmlFor="checkbox">
                          <input type="checkbox" id="checkbox" onClick={this.props.toggleTheme}/>
                          <div className="slider round"></div>
                        </label>
                      </div>
                    </MDBNavItem>
                  </MDBNavbarNav>
              </MDBContainer>
            </MDBNavbar>
          </div>
        </Router>

        <div className="slider-wrapper dark">
          <div className="slide d-flex align-items-center justify-content-center bg_image">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol xl="12" lg="12" md="12">
                  <MDBAnimation type="fadeInLeft" delay=".3s">
                    <div className="inner text-left">
                      <span className='inner-span'>WELCOME TO MY WORLD</span>
                      <h1 className='title2'>Hi, I am Andrey Petunin
                        <br/>
                        <div className="role">{this.state.role}</div>
                      </h1>
                      <h2>based in Los Angeles</h2>
                    </div>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
