import React from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBAnimation, MDBIcon } from 'mdbreact';
import { init, send } from 'emailjs-com';
import contImage from '../assets/contacts-image-1.jpg';

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person_name: '',
      person_email: '',
      subject: '',
      message: '',
      msg: '',
      msg_vis: false,
      bot_field: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.doNotSendMessage = this.doNotSendMessage.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      [e.currentTarget.id]: e.currentTarget.value,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const isValidForm = e.target.checkValidity();
    const message = {
      user_name: this.state.person_name,
      user_email: this.state.person_email,
      subject: this.state.subject,
      user_message: this.state.message,
      date: new Date(),
    };
    if (isValidForm && this.state.bot_field.trim().toLowerCase() !== "white") {
      this.doNotSendMessage();
    }
    if (isValidForm && this.state.bot_field.trim().toLowerCase() === "white") {
      this.sendMessage(message);
    }
  }


  async sendMessage(templateParams) {
    init("user_gpyb1F0W8MHnqruOjkgMC");
    send('my_web_contact_service', 'my_web_contact_form', templateParams)
      .then((res) => {
        // console.log('SUCCESS!', res.status, res.text);
        this.setState({
          msg: 'Message sent! Thank you!',
        }, this.setMsgVis());
      }, (error) => {
        // console.log('FAILED...', error);
        this.setState({
          msg: 'Message was not sent! Please try again or text me via LinkedIn!',
        }, this.setMsgVis());
      });

    this.setState({
      person_name: '',
      person_email: '',
      subject: '',
      message: '',
      bot_field: ''
    });
  }

  doNotSendMessage () {
    console.log("=======FUNC TRIGGERED")
    this.setState({
      msg: 'Hey, bots are not welcomed here...please leave!',
    }, this.setMsgVis());

    this.setState({
      person_name: '',
      person_email: '',
      subject: '',
      message: '',
      bot_field: ''
    });
  }

  setMsgVis() {
    this.setState({
      msg_vis: true,
    });
    setTimeout(() => {
      this.setState({
        msg_vis: false,
        msg: '',
      });
    }, 3000);
  }

  render() {
    return (
      <MDBContainer fluid className={this.props.theme === 'dark' ? "pt-5 pb-4 contacts-wrapper-dark" : "pt-5 pb-4 contacts-wrapper-light"} id="contacts">
        <MDBContainer className="py-2 pt-md-4 align-items-center">
          <MDBRow className="align-items-start">
            <MDBCol xl="6" lg="6" md="12" className="order-2 order-xl-1 order-lg-1">
              <MDBContainer className="text-left mb-3 mt-3 mt-xl-0 mt-lg-0">
                <MDBAnimation reveal type="fadeInDown">
                  <h2 className={this.props.theme === 'dark' ? "title-dark" : "title-light"}>Hire Me.</h2>
                </MDBAnimation>
                <p className={this.props.theme === 'dark' ? "description-dark" : 'description-light'}>
                  I am open to new opportunities and available for freelance work.
                  Connect me via this form or email:
                  <a href="#" className={(this.props.theme === 'dark' ? 'inline-a-dark' : 'inline-a-light') + " disabled"}> andrey.petunin86@gmail.com</a>
                  {/* , by phone
                  <a href="#" className={(this.props.theme === 'dark' ? 'inline-a-dark' : 'inline-a-light') + " disabled"}> 6282022996 </a> */}
                </p>
              </MDBContainer>
              <MDBContainer>
                <form
                  className={this.props.theme === 'dark' ? 'contact-form-dark' : 'contact-form-light'}
                  onSubmit={this.handleFormSubmit}
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <label htmlFor="person_name">
                    <input
                      type="text"
                      name="name"
                      id="person_name"
                      placeholder="Your name *"
                      value={this.state.person_name}
                      onChange={this.handleInputChange}
                      required
                    />
                  </label>
                  <label htmlFor="person_email">
                    <input
                      type="email"
                      name="email"
                      id="person_email"
                      placeholder="Your email *"
                      value={this.state.person_email}
                      onChange={this.handleInputChange}
                      required
                    />
                  </label>
                  <label htmlFor="subject">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Write subject *"
                      value={this.state.subject}
                      onChange={this.handleInputChange}
                      required
                    />
                  </label>
                  <label htmlFor="message">
                    <textarea
                      type="text"
                      id="message"
                      className={this.props.theme === 'dark' ? "textarea-dark" : 'textarea-light'}
                      name="message"
                      placeholder="Your message *"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                      required
                    />
                  </label>
                  <label htmlFor="bot_field">
                    <input
                      type="text"
                      name="bot-field"
                      id="bot_field"
                      placeholder="Zebra is black and ... *"
                      value={this.state.bot_field}
                      onChange={this.handleInputChange}
                      maxLength="10"
                      required
                    />
                  </label>

                  <div className="text-center text-lg-left text-xl-left">
                    <button type="submit" name="submit" className={this.props.theme === 'dark' ? "rn-btn-style-2-dark submit-btn" : "rn-btn-style-2-light submit-btn"}
                    >Submit</button>
                  </div>

                  <div className={'text-center text-lg-left text-xl-left' + this.state.msg_vis ? 'msg_vis' + this.props.theme === 'dark' ? "-dark" : "-light" :
                  'msg_dis' + this.props.theme === 'dark' ? '-dark' : '-light'}>

                    <span className={this.state.msg_vis ? 'check_vis' : this.props.theme === 'dark' ? 'msg_dis-dark' : 'msg_dis-light'}>
                      <MDBIcon far icon="check-circle" size="lg" />
                    </span>
                    <span className={this.props.theme === 'dark' ? 'msg-dark' : 'msg-light'}>{this.state.msg}</span>
                  </div>

                </form>
              </MDBContainer>
            </MDBCol>
            <MDBCol xl="6" lg="6" md="12" className="order-1 order-xl-2 order-lg-2">
              <MDBContainer>
                <img src={contImage} className={this.props.theme === 'dark' ? "contacts-img mt-md-4" : "contacts-img shade mt-md-4"} />
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    );
  }
}

export default Contacts;
