import React, { useState } from 'react';
import Navbar from './components/Navbar';
import About from './components/About';

import Portfolio from './components/Portfolio.js';
import Contacts from './components/Contacts.js';
import Footer from './components/Footer.js';
import BackToTop from './components/BackToTop.js';

const App = () => {
  const [theme, setTheme] = useState('dark');
  const toggleTheme = () => {
    if(theme === 'dark') setTheme('light');
    if(theme === 'light') setTheme('dark');
  }
  return (
    <div style={{height:"100%"}}>
      <Navbar theme={theme} toggleTheme={toggleTheme}/>
      <About theme={theme}/>
      <Portfolio theme={theme}/>
      <Contacts theme={theme}/>
      <Footer theme={theme}/>
      <BackToTop/>
    </div>
  )
};

export default App;