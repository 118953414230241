import React from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact';
import Tabs from './Tabs';
import aboutImage from '../assets/about-image-2.jpeg';

const About = ({ theme }) => (
  <MDBContainer fluid className={theme === 'dark' ? "py-5 about-wrapper-dark" : "py-5 about-wrapper-light"} id="about">
    <MDBContainer className="align-items-center scroll-padding ">
      <MDBRow>
        <MDBCol xl="5" lg="12" md="12" className="text text-center" id="img-col">
          <img src={aboutImage} className={theme === 'light' ? 'img-fluid my-3 about-img-light shade' : 'img-fluid my-3 about-img-dark'} alt=""/>
        </MDBCol>
        <MDBCol xl="7" lg="12" md="12" className="text-white py-5 pl-xl-5 pl-lg-5">
          <MDBRow>
            <MDBCol>
              <MDBAnimation type="fadeInDown" reveal>
                <h1 className={theme === 'dark' ? "h1-responsive title-dark" : "h1-responsive title-light"}>About Me</h1>
              </MDBAnimation>
              <p className={theme === 'dark' ? "description-dark py-3" : "description-light py-3"}>
                Full-stack Software Engineer with a fierce sense of ownership, strong technical and
                interpersonal skills, who loves to work in a fast paced collaborative environment
                while deeply investing in long term quality and efficiency.
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <Tabs theme={theme}/>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </MDBContainer>
);

export default About;
