import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const Tabs = ({ theme }) => {
  const [activeTab, setActiveTab] = useState('Skills');

  const renderContent = () => {
    if (activeTab === 'Skills') {
      return (
        <div className={theme === 'dark' ? "single-tab-content-dark" : "single-tab-content-light"}>
          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Front End</span>
              <span> : JavaScript, React/Redux, React Native, HTML/CSS,</span>
            </div>
            <div>Bootstrap, jQuery, Babel, Webpack, Styled-components, CSS Modules </div>
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Back End:</span>
              <span> Node.js, C, Nginx, Docker, Heroku, AWS (EC2/S3), Git,</span>
            </div>
            <div>MySQL, MongoDB, PostgreSQL, Cassandra</div>
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Tools/Frameworks</span>
              <span> Jest/Enzyme, K6, Loader.io, Suite.st, Appium, Detox,</span>
            </div>
            <div>Mongoose, Jasmine, DataDog, BigQuery, New Relic</div>
          </div>
        </div>
      );
    }

    if (activeTab === 'Experience') {
      return (
        <div className={theme === 'dark' ? "single-tab-content-dark" : "single-tab-content-light"}>
           <div className="mb-4">
            <div className="mb-1">
              <span className="degree">SDET</span>
              <span> - Red Bull Media House | 2021 - now</span>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">SDET</span>
              <span> - CellarEye | 2020-2021</span>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Full Stack Engineer</span>
              <span> - iMovieRate | 2020-2020</span>
            </div>
          </div>

          {/* <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Back End Engineer</span>
              <span> - TEAA | 2020-2020</span>
            </div>
          </div> */}

          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Front End Engineer</span>
              <span> - HotellMe | 2020-2020</span>
            </div>
          </div>

          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">Software Tester</span>
              <span> - Digital Element | 2016-2019</span>
            </div>
          </div>
        </div>
      );
    }

    if (activeTab === 'Education') {
      return (
        <div className={theme === 'dark' ? "single-tab-content-dark" : "single-tab-content-light"}>
          <div className="mb-4">
            <div className="mb-1">
              <span className="degree">CS50 Computer Science Certification</span>
              <span> - Harvard University, Cambridge, MA</span>
            </div>
            <div>2021</div>
          </div>
          <div className="mb-4">
            <div className="line-title">
              <span className="degree">Software Engineering Immersive</span>
              <span> - Hack Reactor, San Francisco, CA</span>
            </div>
            <div>2020</div>
          </div>
          <div className="mb-4">
            <div className="line-title">
              <span className="degree">MS, Business Administration</span>
              <span> - South Ural State University, Chelyabinsk, RU</span>
            </div>
            <div>2009</div>
          </div>
        </div>
      );
    }
  };

  return (
    <MDBContainer id="tabs-cont">
      <div className="d-flex flex-wrap justify-content-start" id={theme === 'dark' ? "tabs-choice-dark" : "tabs-choice-light" }>
        <div className="mr-2 mr-xl-5 mr-lg-5 mr-md-5 mr-sm-3" onClick={() => setActiveTab('Skills')}>
          <a className={activeTab === 'Skills' ? 'selected' : 'not-selected'}>Skills</a>
        </div>
        <div className="mr-2 mr-xl-5 mr-lg-5 mr-md-5 mr-sm-3" onClick={() => setActiveTab('Experience')}>
          <a className={activeTab === 'Experience' ? 'selected' : 'not-selected'}>Experience</a>
        </div>
        <div className="mr-2 mr-xl-5 mr-lg-5 mr-md-5 mr-sm-3" onClick={() => setActiveTab('Education')}>
          <a className={activeTab === 'Education' ? 'selected': 'not-selected'}>Education & Certification</a>
        </div>
      </div>
      <MDBRow className="mt-3 ">
        <MDBCol>
          {renderContent()}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Tabs;
