import React from 'react';
import { MDBIcon } from 'mdbreact';
import { animateScroll as scroll } from 'react-scroll';

const BackToTop = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className="back-to-top">
      <div className="totop-cont" onClick={scrollToTop}>
        <MDBIcon icon="angle-up" size="2x" className="arrow" />
      </div>
    </div>
  );
};

export default BackToTop;