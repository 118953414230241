import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBAnimation } from 'mdbreact';
import logo from '../assets/logo-text.png';

const Footer = ({ theme }) => (
  <MDBContainer fluid className="footer-wrapper">
    <MDBRow className="align-items-center justify-content-between">

      <MDBCol xl="4" lg="4" md="6" sm="6" className="test-col">
        <div className="footer-inner">
          <div className="logo text-center text-sm-left mb-sm-2">
            <a href="#" className="disabled">
              <img src={logo} alt="Logo" className="logo-text" />
            </a>
          </div>
        </div>
      </MDBCol>

      <MDBCol xl="4" lg="4" md="6" sm="6" className="test-col">
        <div className="footer-inner text-center justify-content-between align-items-center">
          <ul className="social-share rn-lg-size d-flex justify-content-center">
              <li>
                <a className="social-share-li-a" href="https://www.linkedin.com/in/andrey-petunin/" target="_blank" rel="noopener noreferrer"><MDBIcon fab icon="linkedin" size="lg" /></a>
              </li>
            <li>
              <a className="social-share-li-a" href="https://github.com/ozzychel" target="_blank" rel="noopener noreferrer"><MDBIcon fab icon="github" size="lg" /></a>
            </li>
              <li>
                <a className="social-share-li-a" href="https://www.instagram.com/ozzy_chel/?hl=en" target="_blank" rel="noopener noreferrer"><MDBIcon fab icon="instagram" size="lg" /></a>
              </li>
          </ul>
        </div>
      </MDBCol>

      <MDBCol xl="4" lg="4" md="12" sm="12" className="test-col">
        <div className="footer-inner text-xl-right text-lg-right text-center mt-md-2 mt-sm-2">
          <p className="footer-inner-p">
            Copyright © 2021 Andrey Petunin.
            <br />
            {' '}
            All Rights Reserved.
          </p>
        </div>
      </MDBCol>

    </MDBRow>
  </MDBContainer>
);

export default Footer;
